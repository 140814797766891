/* ---------------------------------------- */
/* GENERICS */
/* ---------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Tektur:wght@400;900&display=swap');
* {
  font-family: 'Tektur', cursive;
  cursor: none;
  user-select: none;
}
body {
 margin: 0;
 padding: 0;
 color: white;
 background-image: url(../../public/matrix.gif);
 cursor: none;
 transition: blur 1s ease;
}
/* ---------------------------------------- */
/* MOUSE TRACKING */
/* ---------------------------------------- */
.tracker {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
}
.mouse-cursor-gradient-tracking {
  position: relative;
  color: white;
  overflow: hidden;
}
.mouse-cursor-gradient-tracking span {
  position: relative;
}
.mouse-cursor-gradient-tracking::before {
  z-index: 1000;
  content: '';
  position: absolute;
  left: var(--x);
  top: var(--y);
  width: var(--size);
  height: var(--size);
  background: radial-gradient(circle closest-side, #f40000 5px, transparent);
  transform: translate(-50%, -50%);
  transition: width 0.2s ease, height 0.2s ease;
  pointer-events: none;
}
.mouse-cursor-gradient-tracking:hover:before {
  --size: 15px;
}
.mouse-cursor-gradient-tracking:active:before {
  --size: 50px;
}
/* ---------------------------------------- */
/* CONTENT */
/* ---------------------------------------- */
.app {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
}
.porthole {
  position: absolute;
  top: 0;
  left: 0;
  background: radial-gradient(circle at var(--x) var(--y), rgba(22,22,29,0) -150px, rgba(22,22,29,1) 200px);
  height: 100vh;
  width: 100vw;
  pointer-events: none;
}
/* ---------------------------------------- */
/* SCOREBOARD */
/* ---------------------------------------- */
.scoreboard {
  top: 0;
  height: 12.5vh;
  margin: 1.25vh 5vw;
  position: absolute;
  width: 90%;
  max-width: 800px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-radius: 25px;
  border: 3px solid #008511;
  box-shadow: 0px 0px 10px #00F411;
  background-color: #16161d;
}
.scoreboard_title {
 font-size: 50px;
}
.scoreboard_score {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 25px;
  border: 2px solid #00F411;
  padding: .5rem;
  margin: 1rem;
}
.score_title {
  margin: 2px;
  font-size: 20px;
}
.score_score {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 2px;
  width: 100%;
  font-size: 42px;
}
/* ---------------------------------------- */
/* RESULT POPUP */
/* ---------------------------------------- */
.result {
position: absolute;
top: 15vh;
}
.result_text {
  font-size: 32px;
}
/* ---------------------------------------- */
/* PLAYING FIELD */
/* ---------------------------------------- */
.field {
  top: 15vh;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 85vh;
}
/* ---------------------------------------- */
/* RPS BUTTONS */
/* ---------------------------------------- */
.buttons {
  width: 100%;
  height: 100%;
}
.button_row {
  width: 100%;
  height: 50%;
}
.button {
  transition: opacity 1s ease;
  position: absolute;
}
.button_action {
  position: absolute;
  height: 22rem;
  width: 22rem;
  border-radius: 100%;
  background-color: #16161d;
  transition: transform 2s ease;
}
.button_img {
  width: 50%;
  pointer-events: none;
}
.button_action--rock {
  top: 5vh;
  left: calc(25vw - 11rem);
  border: 15px #008511 solid;
  box-shadow: 0px 0px 10px #008511;
}
.button_action--rock:hover {
  box-shadow: inset 0px 0px 10px #008511;
}
.button_action--rock:active {
  box-shadow: inset 0px 0px 100px #008511;
}
.button_action--paper {
  top: 5vh;
  left: calc(75vw - 11rem);
  border: 15px #00F411 solid;
  box-shadow: 0px 0px 10px #00F411;
}
.button_action--paper:hover {
  box-shadow: inset 0px 0px 10px #00F411;
}
.button_action--paper:active {
  box-shadow: inset 0px 0px 100px #00F411;
}
.button_action--scissors {
  top: 5vh;
  left: calc(50vw - 11rem);
  border: 15px #003B00 solid;
  box-shadow: 0px 0px 10px #003B00;
}
.button_action--scissors:hover {
  box-shadow: inset 0px 0px 10px #003B00;
}
.button_action--scissors:active {
  box-shadow: inset 0px 0px 100px #003B00;
}
/* ---------------------------------------- */
/* RULE BUTTON */
/* ---------------------------------------- */
.rules {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 2rem;
  border: 3px solid #008511;
  border-radius: 50px;
  padding: 0 2rem;
}
.rules:active {
  box-shadow: inset 0px 0px 100px #00F411;
}
/* RULES MODAL POPUP */
.rules_modal {
  width: 50%;
  max-width: 800px;
  min-width: 300px;
  height: 50vh;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  background-color: #16161d;
  box-shadow: 0px 3px 10px rgb(99, 99, 99);
  border-radius: 50px;
  opacity: 0;
  transition: opacity 1s ease;
  z-index: -10;
}
.modal_open .field {
  pointer-events: none;
}
.modal_open .app {
  filter: blur(100px);
}
.modal_open .rules_modal {
  opacity: 1;
  z-index: 100;
}
.modal_close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  height: 2rem;
  width: 2rem;
}
.modal_content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 2rem;
  margin-top: 2rem;
}
.modal_title {
  font-size: 42px;
}
.modal_para {

}
/* ---------------------------------------- */
/* REPLAY RED PILL */
/* ---------------------------------------- */
.redo {
  position: absolute;
  background-color: #b31e1e;
  top: 65vh;
  border: 3px solid #850000;
  border-radius: 50px;
  padding: 0 3rem;
  box-shadow: 0px 0px 25px #850000;
  box-shadow: inset 0px 4px 10px #fab7b7;
  pointer-events: none;
  opacity: 0;
  transition: opacity .5s ease;
}
.redo:active {
  box-shadow: inset 0px 0px 100px #850000;
}
.redo_title {
  color: #850000;
}
/* ---------------------------------------- */
/* RSP PLAY MOVEMENT */
/* ---------------------------------------- */
.set {
  pointer-events: none;
}
.set .button_action--rock{
  transform: translateY(20vh) translateX(25vw);
}
.set .button_action--paper{
  transform: translateY(20vh) translateX(-25vw);
}
.set .button_action--scissors{
transform: translateY(-22.5vh);
}
.human_winner{
  transition: transform 2s ease;
  transform: translateY(-15vh) translateX(-25vw);
}
.computer_winner {
  transition: transform 2s ease;
  transform: translateY(-15vh) translateX(25vw);
}
.draw {
  transition: transform 2s ease;
  transform: translateY(-15vh);
}
/* ---------------------------------------- */
/* SMALLER SCREENS */
/* ---------------------------------------- */
@media (max-width: 1024px) {
  .scoreboard_title {
    font-size: 40px;
  }
  .button_action {
    height: 20rem;
    width: 20rem;
  }
  .button_action--rock {
    left: calc(25vw - 10rem);
  }
  .button_action--paper {
    left: calc(75vw - 10rem);
  }
  .button_action--scissors {
    left: calc(50vw - 10rem);
  }
}
@media (max-width: 768px) {
  .scoreboard_title {
    font-size: 40px;
  }
  .button_action {
    height: 18rem;
    width: 18rem;
  }
  .button_action--rock {
    left: calc(25vw - 9rem);
  }
  .button_action--paper {
    left: calc(75vw - 9rem);
  }
  .button_action--scissors {
    left: calc(50vw - 9rem);
  }
}
@media (max-width: 640px) {
  .scoreboard_title {
    font-size: 32px;
  }
  .button_action {
    height: 15rem;
    width: 15rem;
  }
  .button_action--rock {
    left: calc(25vw - 7.5rem);
  }
  .button_action--paper {
    left: calc(75vw - 7.5rem);
  }
  .button_action--scissors {
    left: calc(50vw - 7.5rem);
  }
}
@media (max-width: 640px) {
  .scoreboard_title {
    font-size: 32px;
  }
  .button_action {
    height: 15rem;
    width: 15rem;
  }
  .button_action--rock {
    left: calc(25vw - 7.5rem);
  }
  .button_action--paper {
    left: calc(75vw - 7.5rem);
  }
  .button_action--scissors {
    left: calc(50vw - 7.5rem);
  }
}@media (max-width: 640px) {
  .scoreboard_title {
    font-size: 32px;
  }
  .button_action {
    height: 15rem;
    width: 15rem;
  }
  .button_action--rock {
    left: calc(25vw - 7.5rem);
  }
  .button_action--paper {
    left: calc(75vw - 7.5rem);
  }
  .button_action--scissors {
    left: calc(50vw - 7.5rem);
  }
}@media (max-width: 640px) {
  .scoreboard_title {
    font-size: 32px;
  }
  .scoreboard_score {
    padding: .25rem;
  }
  .button_action {
    height: 15rem;
    width: 15rem;
  }
  .button_action--rock {
    left: calc(25vw - 7.5rem);
  }
  .button_action--paper {
    left: calc(75vw - 7.5rem);
  }
  .button_action--scissors {
    left: calc(50vw - 7.5rem);
  }
}
@media (max-width: 500px) {
  .scoreboard_title {
    font-size: 32px;
  }
  .score_title {
    font-size: 20px;
  }
  .score_score {
    font-size: 40px;
  }
  .button_action {
    height: 12rem;
    width: 12rem;
  }
  .button_action--rock {
    top: 5vh;
    left: calc(25vw - 6rem);
    border: 10px #008511 solid;
    box-shadow: 0px 0px 10px #008511;
  }
  .button_action--paper {
    top: 5vh;
    left: calc(75vw - 6rem);
    border: 10px #00F411 solid;
    box-shadow: 0px 0px 10px #00F411;
  }
  .button_action--scissors {
    top: 5vh;
    left: calc(50vw - 6rem);
    border: 10px #003B00 solid;
    box-shadow: 0px 0px 10px #003B00;
  }
  .redo {
    font-size: 12px;
    top: 60vh;
  }
  .rules {
    font-size: 12px;
  }
  .human_winner{
    transform: translateY(-10vh) translateX(-25vw);
  }
  .computer_winner {
    transform: translateY(-10vh) translateX(25vw);
  }
  .draw {
    transform: translateY(-10vh);
  }
  .modal_title {
    font-size: 30px;
  }
  .modal_para {
    font-size: 12px;
  }
  .modal_close {
    height: 1rem;
    width: 1rem;
  }
  .rules_modal {
    height: 60vh;
  }
  .modal_content {
    padding: 1rem;
  }
}
@media (max-width: 400px) {
  .scoreboard_title {
    font-size: 20px;
  }
  .score_title {
    font-size: 14px;
  }
  .score_score {
    font-size: 28px;
  }
  .button_action {
    height: 8rem;
    width: 8rem;
  }
  .button_action--rock {
    top: 5vh;
    left: calc(25vw - 4rem);
    border: 5px #008511 solid;
    box-shadow: 0px 0px 10px #008511;
  }
  .button_action--paper {
    top: 5vh;
    left: calc(75vw - 4rem);
    border: 5px #00F411 solid;
    box-shadow: 0px 0px 10px #00F411;
  }
  .button_action--scissors {
    top: 5vh;
    left: calc(50vw - 4rem);
    border: 5px #003B00 solid;
    box-shadow: 0px 0px 10px #003B00;
  }
  .redo {
    font-size: 12px;
    top: 50vh;
  }
  .rules {
    font-size: 8px;
  }
  .human_winner{
    transform: translateY(-10vh) translateX(-25vw);
  }
  .computer_winner {
    transform: translateY(-10vh) translateX(25vw);
  }
  .draw {
    transform: translateY(-10vh);
  }
}